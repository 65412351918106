import { BrandLogo } from "components"

export default function Header () {

    return (
        <>
            <BrandLogo height={30} />
        </>
    )

}