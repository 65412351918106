const initial = {}

// Actions
export const setUser = (user: any) => ({
    type: 'SET_USER',
    payload: user,
})

// Reducer
const UserReducer = (state = initial, action: {
    type: string,
    payload: any,
}) => {
    switch (action.type) {
        case 'SET_USER':
            return action.payload;

        default:
            return state;
    }
}

export default UserReducer;
