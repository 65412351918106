import { ActionIcon, Box, Button, Flex, Grid, Loader } from "@mantine/core";
import { useSetState } from "@mantine/hooks";
import { closeAllModals, useModals } from "@mantine/modals";
import { IconArrowBigLeftFilled, IconArrowBigRightFilled, IconCloudUpload, IconTrash } from "@tabler/icons-react";
import { deleteMedia, newMedia } from "api/fetch/media";
import { useQueryMedia } from "api/hook/media";
import { MediaFile } from "api/types";
import { useCallback, useEffect, useRef, useState } from "react";
import { parseErrorMsg } from "utils";

export default function useMediaLibrary({
    type = 'image',
}: {
    type?: string,
}) {

    const { openModal } = useModals();

    const openMediaLibrary = useCallback(({
        onChange,
    }: {
        onChange: (file: MediaFile) => any,
    }) => {
        closeAllModals();
        openModal({
            title: 'Media Library',
            children: <Modal
                onChange={(file: MediaFile) => {
                    onChange(file);
                    closeAllModals();
                }}
                type={type}
            />,
        })
    }, []);

    return {
        openMediaLibrary,
    }

}


const Modal = ({
    onChange,
    type = 'image',
}: {
    onChange?: (file: MediaFile) => any,
    type?: string,
}) => {

    const [isUploading, setIsUploading] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [deletedMedia, setDeletedMedia] = useState<number[]>([]);

    const [pagination, setPagination] = useSetState({
        offset: 0,
        limit: 11,
        search: '',
        type,
    });

    const { data, isFetching } = useQueryMedia({
        ...pagination,
        deletedMedia,
    });

    return (
        <Flex direction={'column'} gap={'md'}>
            {
                isFetching ? (
                    <Grid
                        gutter={'xs'}
                    >
                        {
                            Array.from({ length: pagination.limit + 1 }).map((item, index) => (
                                <Grid.Col
                                    xs={6}
                                    sm={4}
                                    md={3}
                                    key={index}
                                >
                                    <Flex
                                        sx={theme => ({
                                            borderRadius: theme.radius.md,
                                            overflow: 'hidden',
                                            aspectRatio: '1/1',
                                            border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
                                        })}
                                        justify={'center'}
                                        align={'center'}
                                    >
                                        <Loader size={40} />
                                    </Flex>
                                </Grid.Col>
                            ))
                        }
                    </Grid>
                ) : (
                    <Grid
                        gutter={'xs'}
                    >
                        <Grid.Col
                            xs={6}
                            sm={4}
                            md={3}
                        >
                            <input type="file" ref={inputRef} style={{ display: 'none' }} onChange={(e) => {
                                const file = e.target.files?.[0];
                                if (file) {
                                    setIsUploading(true);
                                    newMedia({
                                        file,
                                    }).then((res) => {
                                        onChange && onChange(res);
                                    }).catch((err) => {
                                        err = parseErrorMsg(err);
                                        alert(err);
                                    }).finally(() => {
                                        setIsUploading(false);
                                    });
                                }
                            }} />
                            <Box
                                sx={theme => ({
                                    borderRadius: theme.radius.md,
                                    overflow: 'hidden',
                                    cursor: isUploading ? 'not-allowed' : 'pointer',
                                    aspectRatio: '1/1',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
                                    background: theme.colors.indigo[7]
                                })}
                                onClick={() => {
                                    if (isUploading) return;
                                    inputRef.current?.click();
                                }}
                            >
                                {
                                    isUploading ? (
                                        <Loader size={40} color="white" />
                                    ) : (
                                        <IconCloudUpload color="white" size={40} />
                                    )
                                }
                            </Box>
                        </Grid.Col>
                        {
                            data?.files?.map((item: MediaFile) => (
                                <MediaItem
                                    key={item.id}
                                    item={item}
                                    onChange={onChange}
                                    onDelete={() => {
                                        setDeletedMedia([...deletedMedia, item.id]);
                                    }}
                                    type={type}
                                />
                            ))
                        }
                    </Grid>
                )
            }
            <Flex
                justify="center"
                pt="xs"
                gap={'xs'}
            >
                <Button
                    onClick={() => {
                        setPagination({
                            offset: pagination.offset - pagination.limit,
                        });
                    }}
                    disabled={pagination.offset === 0 || isFetching}
                    radius={'xl'}
                >
                    <IconArrowBigLeftFilled />
                </Button>
                <Button
                    onClick={() => {
                        setPagination({
                            offset: pagination.offset + pagination.limit,
                        });
                    }}
                    disabled={data?.files?.length !== pagination.limit || isFetching}
                    radius={'xl'}
                >
                    <IconArrowBigRightFilled />
                </Button>
            </Flex>
        </Flex>
    )

}
const MediaItem = ({
    item,
    onChange,
    onDelete,
    type,
}: {
    item: MediaFile,
    onChange?: (file: MediaFile) => any,
    onDelete: () => any,
    type: string,
}) => {

    const [isHover, setIsHover] = useState(false);
    const ref = useRef<HTMLVideoElement>(null);

    // on hover play
    useEffect(() => {
        if (type === 'video' && ref.current) {
            if (isHover) ref.current.play();
            else ref.current.pause();
        }
    }, [isHover]);

    return (
        <Grid.Col
            xs={6}
            sm={4}
            md={3}
            key={item.id}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
        >
            <Box
                sx={{
                    position: 'relative',
                    '&:hover .remove-btn': {
                        display: 'block',
                    }
                }}
            >
                <Box
                    sx={theme => ({
                        borderRadius: theme.radius.md,
                        overflow: 'hidden',
                        cursor: 'pointer',
                        background: `url(${item.medium?.url}) center center / cover no-repeat`,
                        aspectRatio: '1/1',
                    })}
                    onClick={() => {
                        onChange && onChange(item);
                    }}
                >
                    {
                        item.name
                    }
                </Box>
                {
                    // if it's video on hover show video and start playing to preview
                    // if it's image do nothing

                    type === 'video' && (
                        <video
                            src={(item as MediaFile).full?.url || undefined}
                            style={{
                                cursor: 'pointer',
                                overflow: 'hidden',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                            }}
                            loop
                            ref={ref}
                            onClick={() => {
                                onChange && onChange(item);
                            }}
                        />
                    )
                }
                <Box
                    className="remove-btn"
                    sx={{
                        display: 'none',
                        position: 'absolute',
                        bottom: 5,
                        right: 5,
                    }}
                >
                    <ActionIcon color="red" variant="filled" radius="md" size={'sm'}
                        onClick={(e) => {
                            onDelete();
                        }}
                    >
                        <IconTrash size={16} />
                    </ActionIcon>
                </Box>
            </Box>
        </Grid.Col>
    )

}