import LogoWhite from 'assets/logo_white.svg';
import LogoBlack from 'assets/logo_black.svg';
import { useMantineTheme } from '@mantine/core';

export default function BrandLogo({
    colorScheme = undefined,
    ...props
}: {
    colorScheme ?: 'light' | 'dark' | undefined;
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) {

    const themeColorScheme = useMantineTheme().colorScheme;
    if (colorScheme === undefined) {
        colorScheme = themeColorScheme;
    }

    return <img {...props} src={colorScheme === 'light' ? LogoBlack : LogoWhite} alt="Logo" />;

}