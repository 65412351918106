import { Box, Button, Grid, NumberInput, PasswordInput, Select, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form';
import { deleteUser, updateUser } from 'api/fetch/user';
import { User } from 'api/types';
import { useState } from 'react';
import { parseErrorMsg } from 'utils';
import { modals } from '@mantine/modals';

export default function CategoryNew({
    user,
    onUpdate,
}: {
    user: User
    onUpdate?: () => void
}) {

    const form = useForm({
        initialValues: {
            name: user.name || '',
            role: user.role || 'subscriber',
            special_user: user.special_user || 0,
        } as {
            name: string
            role: string,
            special_user: number,
        }
    });

    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    return (
        <Box>
            <Box
                p={'md'}
                sx={theme => ({
                    maxWidth: 600,
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                })}
            >
                <form onSubmit={form.onSubmit((values) => {
                    setLoading(true);
                    updateUser({
                        id: user.id,
                        ...values,
                    }).then(() => {
                        onUpdate && onUpdate();
                        form.reset();
                    }).catch((error) => {
                        alert(parseErrorMsg(error));
                    }).finally(() => {
                        setLoading(false);
                    });
                })}>
                    <Grid>
                        <Grid.Col span={12}>
                            <TextInput
                                label="Full Name"
                                {...form.getInputProps('name')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Select
                                label="Role"
                                data={[
                                    { value: 'subscriber', label: 'Subscriber' },
                                    { value: 'editor', label: 'Editor' },
                                ]}
                                {...form.getInputProps('role')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <NumberInput label="Special User" {...form.getInputProps('special_user')} />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                type="submit"
                                fullWidth
                                loading={loading}
                            >
                                Save
                            </Button>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                fullWidth
                                loading={deleteLoading}
                                disabled={loading}
                                color="red"
                                variant="outline"
                                onClick={() => {
                                    // show confirmation modal
                                    setDeleteLoading(true);
                                    modals.openConfirmModal({
                                        title: 'Please confirm deletion',
                                        children: (
                                            <Text size="sm">
                                                You will not be able to recover this user! please confirm deletion or cancel action.
                                            </Text>
                                        ),
                                        labels: { confirm: 'Delete', cancel: 'Cancel' },
                                        onCancel: () => {
                                            setDeleteLoading(false);
                                        },
                                        onConfirm: () => {
                                            deleteUser({
                                                id: user.id,
                                            }).then(() => {
                                                onUpdate && onUpdate();
                                            }).catch((error) => {
                                                alert(parseErrorMsg(error));
                                            }).finally(() => {
                                                setDeleteLoading(false);
                                            });
                                        },
                                    });

                                }}
                            >
                                Delete
                            </Button>
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Box>
    );

}