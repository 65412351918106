import { apiWithInterceptor } from './';
import axios, { CancelTokenSource } from 'axios';

let cancelTokens: { [key: string]: CancelTokenSource } = {};

export const getMedia = async ({
    offset = 0,
    limit = 20,
    search = "",
    type = "image",
}: {
    offset?: number,
    limit?: number,
    search?: string,
    type?: string,
}) : Promise<any> => {
    cancelTokens.getCategories && cancelTokens.getCategories.cancel();
    cancelTokens.getCategories = axios.CancelToken.source();
    return apiWithInterceptor.get("/media", {
        params: { 
            offset, limit, search, type
        },
    });
};

export const getMediaFile = async ({
    id,
}: {
    id?: number,
}) : Promise<any> => {
    cancelTokens.getCategories && cancelTokens.getCategories.cancel();
    cancelTokens.getCategories = axios.CancelToken.source();
    return apiWithInterceptor.get(`/media/${id}`);
};

export const newMedia = async (data: {
    file: any,
}) : Promise<any> => apiWithInterceptor.post("/media", data);


export const deleteMedia = async ({
    id,
}: {
    id: number,
}) => apiWithInterceptor.delete(`/media/${id}`);
