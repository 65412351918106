import {
    useQuery,
} from '@tanstack/react-query'

import {
    getMedia,
} from 'api/fetch/media';

export const useQueryMedia = (fetchDataOptions: {
    offset: number,
    limit: number,
    search?: string,
    type?: string,
    deletedMedia?: number[],
}) => {
    const deletedMedia = fetchDataOptions.deletedMedia || [];
    fetchDataOptions = {
        ...fetchDataOptions,
        deletedMedia: undefined,
    };
    const { data, error, isFetching, refetch } = useQuery(
        ['media', fetchDataOptions, deletedMedia],
        () => getMedia(fetchDataOptions),
        {
            staleTime: 60 * 1000,
            cacheTime: 0,
            retry: false,
            keepPreviousData: true,
        }
    );

    return {
        data,
        refetch,
        error,
        isFetching,
    }
};