export default function parseErrorMsg(error: any) {
    let msg = "";
    if (error.response) {
        msg = error.response.data.message;
    } else if (error.message) {
        msg = error.message;
    } else {
        msg = error.request;
    }
    return msg;
}