import { Box, Button, Grid, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form';
import { newCategory } from 'api/fetch/category';
import { useState } from 'react';
import { parseErrorMsg } from 'utils';

export default function CategoryNew({
    onUpdate,
}: {
    onUpdate?: () => void
}) {

    const form = useForm({
        initialValues: {
            name_krd: '',
            name_en: '',
            name_ar: '',
        },
        validate: {
            name_krd: (value) => value.length < 3 && 'Title is too short',
            name_en: (value) => value.length < 3 && 'Title is too short',
            name_ar: (value) => value.length < 3 && 'Title is too short',
        }
    });

    const [loading, setLoading] = useState(false);

    return (
        <Box>
            <Box
                p={'md'}
                sx={theme => ({
                    maxWidth: 600,
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                })}
            >
                <form onSubmit={form.onSubmit((values) => {
                    setLoading(true);
                    newCategory(values).then(() => {
                        onUpdate && onUpdate();
                        form.reset();
                    }).catch((error) => {
                        alert(parseErrorMsg(error));
                    }).finally(() => {
                        setLoading(false);
                    });
                })}>
                    <Grid>
                        <Grid.Col span={12}>
                            <Title>
                                Title
                            </Title>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                label="Title in Arabic"
                                dir='rtl'
                                sx={{
                                    '& input': {
                                        textAlign: 'right',
                                    }
                                }}
                                {...form.getInputProps('name_ar')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                dir='rtl'
                                sx={{
                                    '& input': {
                                        textAlign: 'right',
                                    }
                                }}
                                label="Title in Kurdish"
                                {...form.getInputProps('name_krd')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                label="Title in English"
                                {...form.getInputProps('name_en')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                type="submit"
                                fullWidth
                                loading={loading}
                            >
                                Publish
                            </Button>
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Box>
    );

}