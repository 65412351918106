import dayjs from 'dayjs';
import { apiWithInterceptor } from './';

export interface RealtimeResponse {
    minutes: string,
    views: number,
    votes: number,
    unvotes: number,
}

export const getRealtime = async () => apiWithInterceptor.get("/analysis/realtime");

export const getChartViews = async ({
    before = dayjs().format("YYYY-MM-DD"),
    after = dayjs().subtract(7, "day").format("YYYY-MM-DD"),
}: {
    before?: string;
    after?: string;
}) => apiWithInterceptor.get("/analysis/chart/views", {
    params: {
        before: dayjs(before).format("YYYY-MM-DD"),
        after: dayjs(after).format("YYYY-MM-DD"),
    }
});

export const getChartVotes = async ({
    before = dayjs().format("YYYY-MM-DD"),
    after = dayjs().subtract(7, "day").format("YYYY-MM-DD"),
}: {
    before?: string;
    after?: string;
}) => apiWithInterceptor.get("/analysis/chart/votes", {
    params: {
        before: dayjs(before).format("YYYY-MM-DD"),
        after: dayjs(after).format("YYYY-MM-DD"),
    }
});