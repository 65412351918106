
import { Box, Skeleton, Table as TableMantine, useMantineTheme } from '@mantine/core'
import { useSetState } from '@mantine/hooks'
import { IconArrowNarrowDown, IconArrowNarrowUp } from '@tabler/icons-react'
import { useQuery } from '@tanstack/react-query'
import {
    ColumnDef,
    SortingState,
    flexRender,
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
} from '@tanstack/react-table'
import { getOpinions } from 'api/fetch/opinion'
import { TablePagination } from 'components'
import { useEffect, useMemo, useRef, useState } from 'react'
import { parseErrorMsg } from 'utils'
import TableSearch from './TableSearch'


interface PaginationState {
    pageIndex: number;
    pageSize: number;
}

export default function Table({
    endpoint,
    columns,
    defaultPageSize = 20,
    toggleData,
    cacheKey,
    onRowClick,
}: {
    endpoint: any,
    columns: ColumnDef<any, any>[]
    defaultPageSize?: number
    toggleData?: boolean
    cacheKey: string
    onRowClick?: (row: any) => void
}) {

    const [{ pageIndex, pageSize }, setPagination] = useSetState<PaginationState>({
        pageIndex: 0,
        pageSize: defaultPageSize,
    });

    const [sorting, setSorting] = useState<SortingState>([])
    const [search, setSearch] = useState<string>('')

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize]
    )

    const fetchDataOptions = useMemo(() => {        
        return {
            pageIndex,
            pageSize,
            sorting,
            search,
        };
    }, [pageIndex, pageSize, sorting, search]);

    useEffect(() => {
        refetch();
    }, [toggleData, search]);

    const tableHeaderRef = useRef<HTMLTableSectionElement>(null);

    // useEffect(() => {
    //     const handleScroll = () => {
    //         const scrollY = window.scrollY;
    //         const tableHeader = tableHeaderRef.current;
    //         if (tableHeader) {
    //             if (scrollY < 63) {
    //                 tableHeader.style.top = `${scrollY}px`;
    //             } else {
    //                 tableHeader.style.top = `${scrollY - 100 + 37}px`;
    //             }
    //         }
    //     }
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     }
    // }, []);

    const {
        data,
        error,
        isFetching,
        refetch
    } = useQuery(
        [cacheKey, fetchDataOptions],
        () => endpoint(fetchDataOptions),
        {
            keepPreviousData: true,
            staleTime: 60000 * 5,
        }
    );

    const table = useReactTable({
        data: data?.data ?? [],
        onPaginationChange: setPagination,
        pageCount: Math.max(Math.ceil(data?.total / pageSize) ?? 0, 1),
        columns,
        getCoreRowModel: getCoreRowModel(),
        manualPagination: true,
        state: {
            pagination,
            sorting,
            globalFilter: search,
        },
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
    });

    const theme = useMantineTheme();

    return (
        <>
            <TableSearch table={table} onSearch={setSearch} />
            <TablePagination table={table} />
            <Box
                sx={{
                    width: '100%',
                    overflowX: 'auto',
                }}
            >
                <TableMantine>
                    <thead
                        ref={tableHeaderRef}
                        style={{
                            position: 'relative',
                            top: 0,
                            zIndex: 9,
                            backgroundColor: theme.colors.dark[6],
                        }}
                    >
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th
                                        style={{
                                            ...(header.column.getCanSort() && {
                                                cursor: 'pointer',
                                                userSelect: 'none',
                                            }),
                                        }}
                                        key={header.id}
                                        onClick={header.column.getToggleSortingHandler()}
                                    >
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                        {{
                                            asc: <IconArrowNarrowUp size={16} />,
                                            desc: <IconArrowNarrowDown size={16} />,
                                        }[header.column.getIsSorted() as string] ?? null}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody
                        style={{
                            backgroundColor: theme.colors.dark[7],
                        }}
                    >
                        {
                            isFetching ? (
                                <>{
                                    [...Array(pageSize)].map((_, i) => (
                                        <tr key={i}>
                                            {
                                                [...Array(columns.length)].map((_, i) => (
                                                    <td key={i}>
                                                        <Skeleton width={'100%'} height={20} />
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }</>
                            ) : error ? (
                                <tr>
                                    <td colSpan={columns.length}>
                                        Error: {parseErrorMsg(error)}
                                    </td>
                                </tr>
                            ) : (
                                <>{
                                    table.getRowModel().rows.map(row => (
                                        <tr
                                            key={row.id}
                                            onClick={() => onRowClick?.(row)}
                                        >
                                            {row.getVisibleCells().map((cell, index) => (
                                                <td key={cell.id} style={{
                                                    // minWidth: index === 0 ? 400 : 'max-content',
                                                }}>
                                                    <Box
                                                        sx={{
                                                            'min-width': 'max-content',
                                                        }}
                                                    >
                                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                    </Box>
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                }</>
                            )}
                    </tbody>
                </TableMantine>
            </Box>
        </>
    )
}