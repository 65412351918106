import { Box, Button, Grid, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form';
import { deleteCategory, updateCategory } from 'api/fetch/category';
import { Category } from 'api/types';
import { useState } from 'react';
import { parseErrorMsg } from 'utils';
import { modals } from '@mantine/modals';

export default function CategoryNew({
    category,
    onUpdate,
}: {
    category: Category
    onUpdate?: () => void
}) {

    const form = useForm({
        initialValues: {
            name_krd: category?.name_krd || '',
            name_en: category?.name_en || '',
            name_ar: category?.name_ar || '',
        },
    });

    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    return (
        <Box>
            <Box
                p={'md'}
                sx={theme => ({
                    maxWidth: 600,
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                })}
            >
                <form onSubmit={form.onSubmit((values) => {
                    setLoading(true);
                    updateCategory({
                        id: category.id,
                        ...values,
                    }).then(() => {
                        onUpdate && onUpdate();
                        form.reset();
                    }).catch((error) => {
                        alert(parseErrorMsg(error));
                    }).finally(() => {
                        setLoading(false);
                    });
                })}>
                    <Grid>
                        <Grid.Col span={12}>
                            <Title>
                                Title
                            </Title>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                label="Title in Arabic"
                                dir='rtl'
                                sx={{
                                    '& input': {
                                        textAlign: 'right',
                                    }
                                }}
                                {...form.getInputProps('name_ar')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                dir='rtl'
                                sx={{
                                    '& input': {
                                        textAlign: 'right',
                                    }
                                }}
                                label="Title in Kurdish"
                                {...form.getInputProps('name_krd')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                label="Title in English"
                                {...form.getInputProps('name_en')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                type="submit"
                                fullWidth
                                loading={loading}
                            >
                                Save
                            </Button>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                fullWidth
                                loading={deleteLoading}
                                disabled={loading}
                                color="red"
                                variant="outline"
                                onClick={() => {
                                    // show confirmation modal
                                    setDeleteLoading(true);
                                    modals.openConfirmModal({
                                        title: 'Please confirm deletion',
                                        children: (
                                            <Text size="sm">
                                                You will not be able to recover this category! please confirm deletion or cancel action.
                                            </Text>
                                        ),
                                        labels: { confirm: 'Delete', cancel: 'Cancel' },
                                        onCancel: () => {
                                            setDeleteLoading(false);
                                        },
                                        onConfirm: () => {
                                            deleteCategory({
                                                id: category.id,
                                            }).then(() => {
                                                onUpdate && onUpdate();
                                            }).catch((error) => {
                                                alert(parseErrorMsg(error));
                                            }).finally(() => {
                                                setDeleteLoading(false);
                                            });
                                        },
                                    });

                                }}
                            >
                                Delete
                            </Button>
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Box>
    );

}