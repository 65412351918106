import { Button, Flex, LoadingOverlay, NumberInput, PasswordInput, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useViewportSize } from '@mantine/hooks';
import { IconArrowBackUp, IconAsterisk, IconAt } from '@tabler/icons';
import { PasswordStrength } from 'components';
import { CenterFocus, WarperLoginForm } from 'layout';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function LoginForgotPassword() {

    const navigate = useNavigate();
    const { width } = useViewportSize();
    const [btnSize, setBtnSize] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xl'>('sm');
    const [stage, setStage] = useState<'email' | 'code' | 'password'>('email');

    useEffect(() => {
        if (width < 350) {
            setBtnSize('xs');
        } else {
            setBtnSize('sm');
        }
    }, [width]);

    const formEmail = useForm({
        initialValues: {
            email: '',
        },
        validate: {
            email: (value) => (value.length < 5 || !value.match(/^[A-Z0-9._-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) ? "Invalid email address" : null),
        }
    });

    const formCode = useForm({
        initialValues: {
            code: '',
            email: '',
        },
        validate: {
            code: (value) => (String(value).length !== 6 ? "Invalid code" : null),
        }
    });

    const formPassword = useForm({
        initialValues: {
            password: '',
            passwordConfirm: '',
            passwordStrength: 0,
            token: '',
        },
    });

    return (
        <CenterFocus>
            <WarperLoginForm>

                {stage === 'email' && (
                    <form
                        onSubmit={formEmail.onSubmit(values => {
                            console.log(values);
                            setStage('code');
                        })}
                    >
                        <Flex
                            direction="column"
                            gap="xs"
                            pb={'lg'}
                            style={{
                                maxWidth: 300,
                                margin: '0 auto',
                            }}
                        >
                            <LoadingOverlay visible={false} overlayBlur={2} />
                            <Button
                                onClick={() => navigate('/')}
                                leftIcon={<IconArrowBackUp size={20} />}
                                variant="subtle"
                                size={btnSize}
                            >
                                {"Back"}
                            </Button>
                            <TextInput
                                placeholder={'Email' as string}
                                label={'Email' as string}
                                variant="filled"
                                size="sm"
                                icon={<IconAt size={20} />}
                                {...formEmail.getInputProps('email')}
                            />
                            <Button
                                variant="gradient"
                                type="submit"
                                size={btnSize}
                                mt={'sx'}
                            >
                                {'Send Code'}
                            </Button>
                        </Flex>
                    </form>
                )}


                {stage === 'code' && (
                    <form
                        onSubmit={formCode.onSubmit(values => {
                            setStage('password');
                            console.log(values);
                        })}
                    >
                        <Flex
                            direction="column"
                            gap="xs"
                            pb={'lg'}
                            style={{
                                maxWidth: 300,
                                margin: '0 auto',
                            }}
                        >
                            <LoadingOverlay visible={false} overlayBlur={2} />
                            <Button
                                onClick={() => navigate('/')}
                                leftIcon={<IconArrowBackUp size={20} />}
                                variant="subtle"
                                size={btnSize}
                            >
                                {"Back"}
                            </Button>
                            <NumberInput
                                placeholder={'Code' as string}
                                label={'Code' as string}
                                variant="filled"
                                size="sm"
                                icon={<IconAsterisk size={20} />}
                                {...formCode.getInputProps('code')}
                            />
                            <Button
                                variant="gradient"
                                type="submit"
                                size={btnSize}
                                mt={'sx'}
                            >
                                {'Verify Code'}
                            </Button>
                        </Flex>
                    </form>
                )}

                {stage === 'password' && (
                    <form
                        onSubmit={formPassword.onSubmit(values => {

                            if (values.password !== values.passwordConfirm) {
                                formPassword.setErrors({
                                    passwordConfirm: "Passwords do not match",
                                });
                                return;
                            }

                            if (values.passwordStrength < .5) {
                                formPassword.setErrors({
                                    password: "Password is too weak",
                                });
                                return;
                            }

                            navigate('/');

                            console.log(values);
                        })}
                    >
                        <Flex
                            direction="column"
                            gap="xs"
                            pb={'lg'}
                            style={{
                                maxWidth: 300,
                                margin: '0 auto',
                            }}
                        >
                            <LoadingOverlay visible={false} overlayBlur={2} />
                            <Button
                                onClick={() => navigate('/')}
                                leftIcon={<IconArrowBackUp size={20} />}
                                variant="subtle"
                                size={btnSize}
                            >
                                {"Back"}
                            </Button>
                            <PasswordInput
                                placeholder={'Password' as string}
                                label={'Password' as string}
                                variant="filled"
                                size="sm"
                                icon={<IconAsterisk size={20} />}
                                {...formPassword.getInputProps('password')}
                            />
                            <PasswordInput
                                placeholder={'Confirm Password' as string}
                                label={'Confirm Password' as string}
                                variant="filled"
                                size="sm"
                                icon={<IconAsterisk size={20} />}
                                {...formPassword.getInputProps('passwordConfirm')}
                            />
                            <PasswordStrength
                                value={formPassword.values.password}
                                onChange={(value) => {
                                    formPassword.setFieldValue('passwordStrength', value);
                                }}
                            />
                            <Button
                                variant="gradient"
                                type="submit"
                                size={btnSize}
                                mt={'sx'}
                            >
                                {'Change Password'}
                            </Button>
                        </Flex>
                    </form>
                )}

            </WarperLoginForm>
        </CenterFocus>
    );

}