import { Badge } from '@mantine/core';
import { IconCrown } from '@tabler/icons-react';

export default function RoleBadge({
    role
}: {
    role: "subscriber" | "editor" | "administrator"
}) {

    if (role === "subscriber") return <></>;

    return <Badge
        variant="light"
        color={"yellow"}
        radius="xl"
        sx={{ marginLeft: '5px' }}
    >
        {role} <IconCrown size={14} style={{ verticalAlign: 'sub' }} />
    </Badge>;

}