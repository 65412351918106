import { apiWithInterceptor } from './';

export const sendNotification = async (data: {
    title: string
    body: string
    payload_key: string
    payload_value: string
    language: string
    login_status: string
}) : Promise<any> => apiWithInterceptor.post("/notification", {
    ...data,
});
