// vendors
import * as React from 'react'
import { useEffect } from 'react';
import { MantineProvider } from '@mantine/core';
import { useLocalStorage, useSetState } from '@mantine/hooks';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';

// utils
import Router from './router';
import 'assets/App.css';
import { default as themeObj } from 'data/theme.js';

function App() {

    const defaultTheme = React.useMemo(() => (themeObj), []);
    const [theme, setTheme] = useSetState(defaultTheme);

    const [colorScheme] = useLocalStorage({ key: 'color-scheme', defaultValue: 'dark' });
    useEffect(() => {
        setTheme({ colorScheme: colorScheme, dir: 'ltr' });
    }, [colorScheme])

    return (
        <MantineProvider
            theme={theme as object}
            withGlobalStyles
            withNormalizeCSS
        >
            <ModalsProvider>
                <Notifications />
                <Router />
            </ModalsProvider>
        </MantineProvider>
    );

}

export default App;
