import dayjs from 'dayjs';
import { apiWithInterceptor } from './';

export const getAccount = async () : Promise<any> => apiWithInterceptor.get("/account");

export const updateAccount = async (data: {
    name?: string,
    email?: string,
    birth_date?: string,
    phone_number?: string,
    education?: string,
    gender?: string,
    work?: string,
}) : Promise<any> => apiWithInterceptor.patch("/account", {
    ...data,
    ...(data.birth_date && { birth_date: dayjs(data.birth_date).format("YYYY-MM-DD") }),
});

export const updateAccountPicture = async (data: {
    picture: any,
}) : Promise<any> => apiWithInterceptor.post("/account/picture", data);