import { Button, Card, Flex, Image, Text } from "@mantine/core";
import { IconPhotoFilled } from "@tabler/icons-react";
import { getMediaFile } from "api/fetch/media";
import { MediaFile } from "api/types";
import useMediaLibrary from "hooks/useMediaLibrary";
import { useEffect, useState } from "react";

export default function MediaLibraryButton({
    onChange,
    value,
    type = 'image',
}: {
    onChange?: (value: any) => {}
    value?: MediaFile
    type?: string
}) {

    const { openMediaLibrary } = useMediaLibrary({ type });
    const [selected, setSelected] = useState<MediaFile | null>(null);

    useEffect(() => {
        console.log(`value: ${value}, type: ${type}`);
        
        if (value) {
            if (typeof value === 'number') {
                if (value <= 0) return;
                getMediaFile({
                    id: value,
                }).then((file) => {
                    setSelected(file);
                }).catch(() => {
                    setSelected(null);
                });
            } else {
                setSelected(value as MediaFile);
            }
        }
    }, [value]);

    return (
        <Flex direction={'column'} gap={'md'}>
            {
                selected ? (
                    <Flex direction={'column'}>
                        {
                            (selected as MediaFile).medium?.url ? (
                                <Image
                                    // src={selected?.medium?.url}
                                    src={(selected as MediaFile).medium?.url || null}
                                    sx={theme => ({
                                        border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]}`,
                                        cursor: 'pointer',
                                        borderRadius: `${theme.radius.md} ${theme.radius.md} 0 0`,
                                        overflow: 'hidden',
                                    })}
                                    onClick={() => {
                                        openMediaLibrary({
                                            onChange: (file: MediaFile) => {
                                                setSelected(file);
                                                onChange && onChange(file);
                                            }
                                        });
                                    }}
                                />
                            ) : (
                                <video
                                    src={(selected as MediaFile).full?.url || undefined}
                                    controls
                                    style={{
                                        cursor: 'pointer',
                                        overflow: 'hidden',
                                    }}
                                    onClick={() => {
                                        openMediaLibrary({
                                            onChange: (file: MediaFile) => {
                                                setSelected(file);
                                                onChange && onChange(file);
                                            }
                                        });
                                    }}
                                />
                            )
                        }
                        {/* <Text>
                            {(selected as MediaFile | VideoFile).name} - {type}
                        </Text> */}
                        <Button
                            onClick={() => {
                                setSelected(null);
                                onChange && onChange(-1);
                            }}
                            variant={'light'}
                            fullWidth
                            color={'red'}
                            size="xs"
                            sx={theme => ({
                                borderRadius: `0 0 ${theme.radius.md} ${theme.radius.md}`
                            })}
                        >
                            Remove
                        </Button>
                    </Flex>
                ) : (
                    <Card
                        onClick={() => {
                            openMediaLibrary({
                                onChange: (file: MediaFile) => {
                                    setSelected(file);
                                    onChange && onChange(file);
                                }
                            });
                        }}
                        sx={theme => ({
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: 100,
                            gap: theme.spacing.xs,
                        })}
                        withBorder
                        radius={'md'}
                    >
                        <IconPhotoFilled />
                        select {type}
                    </Card>
                )
            }
        </Flex>
    )

}