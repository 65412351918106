import { Box, Text, Flex, Progress } from "@mantine/core";
import { useEffect, useState } from "react";

export default function PasswordStrength({
    value,
    onChange,
}: {
    value: string;
    onChange?: (value: number) => void;
}) {

    const [strength, setStrength] = useState(0);

    useEffect(() => {
        let score = 0;
        if (value.length > 8) score++;
        if (value.match(/[a-z]/)) score++;
        if (value.match(/[A-Z]/)) score++;
        if (value.match(/\d+/)) score++;
        if (value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) score += 2;
        if (value.length > 12) score++;
        if (value.length > 16) score++;
        score = score / 8;
        setStrength(score);
        if (onChange) onChange(score);
    }, [value]);

    return (
        <Flex direction="column" gap={5}>
            <Progress
                value={strength * 100}
                variant="gradient"
                color={strength < 0.5 ? "red" : strength < 0.6 ? "orange" : strength < 0.7 ? "yellow" : "green"}
            />
            <Box>
                <Text
                    size="xs"
                    color={value.match(/[a-z]/) ? "green" : "red"}
                >
                    a-z
                </Text>
                <Text
                    color={value.match(/[A-Z]/) ? "green" : "red"}
                    size="xs"
                >
                    A-Z
                </Text>
                <Text
                    size="xs"
                    color={value.match(/\d+/) ? "green" : "red"}
                >
                    0-9
                </Text>
                <Text
                    size="xs"
                    color={value.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/) ? "green" : "red"}
                >
                    !@#$%^&*()_+
                </Text>
                <Text
                    size="xs"
                    color={value.length >= 8 ? "green" : "red"}
                >
                    8-20
                </Text>
            </Box>
        </Flex>
    )

}