import { Flex, Text, useMantineTheme } from "@mantine/core";
import { VotesResult } from "api/types";

export default function VoteResult({
    results = {
        total: 0,
        types: [
            0, 0, 0, 0, 0,
        ],
    },
}: {
    results: VotesResult
}) {

    const total = results.total;
    const data = results.types;

    const theme = useMantineTheme();

    const mergedData = [...Array(5)].map((_, index) => ({
        value: data[index],
        percent: total ? (data[index] / total) * 100 : 0,
        bg: theme.colors.vote[index],
        color: theme.colors.voteInverse[index],
    }));

    return (
        <Flex
            sx={theme => ({
                borderRadius: 10,
                overflow: 'hidden',
                height: 40,
                marginLeft: theme.spacing.md,
                marginRight: theme.spacing.md,
            })}
        >
            {
                mergedData.map((item, index) => (
                    <Flex key={index} bg={item.bg} justify={'center'} align={'center'}
                        sx={{
                            cursor: 'pointer',
                            transition: 'all 0.2s ease',
                            opacity: 1,
                            '&:hover': {
                                opacity: 0.9,
                            },
                            flexGrow: Math.max(Math.round(item.percent), 1),
                            padding: '0 5px',
                            // width: `${item.percent}%`,
                        }}
                    >
                        <Text
                            sx={(theme) => ({
                                fontSize: theme.fontSizes.xs,
                            })}
                            color={item.color}
                            style={{ lineHeight: 1 }}
                            align={'center'}
                        >
                            {/* fix to 1 decimal place */}
                            {Number(item.percent || 0).toFixed(1)}%
                        </Text>
                    </Flex>
                ))
            }
        </Flex>
    )

    // const width = window.innerWidth;
    // const theme = useMantineTheme();

    // // merge defaultData with data
    // const mergedData = [...Array(5)].map((_, index) => ({
    //     label: data[index] ? data[index].label : null,
    //     value: data[index] ? data[index].value : null,
    //     bg: theme.colors.vote[index],
    //     color: theme.colors.voteInverse[index],
    // }));


    // return (
    //     <Flex
    //         style={{
    //             borderRadius: 10,
    //             overflow: 'hidden',
    //             height: 40,
    //             marginLeft: width < 400 ? 0 : theme.spacing.md,
    //             marginRight: width < 400 ? 0 : theme.spacing.md,
    //         }}
    //     >
    //         {
    //             mergedData.map((item, index) => (
    //                 <Flex key={index} bg={item.bg} justify={'center'} align={'center'}
    //                     sx={{
    //                         cursor: 'pointer',
    //                         transition: 'all 0.2s ease',
    //                         opacity: 1,
    //                         '&:hover': {
    //                             opacity: 0.9,
    //                         },
    //                         flexGrow: 1,
    //                         padding: '0 5px',
    //                     }}
    //                 >
    //                     <Text
    //                         sx={(theme) => ({
    //                             fontSize: theme.fontSizes.xs,
    //                         })}
    //                         color={item.color}
    //                         style={{ lineHeight: 1 }}
    //                         align={'center'}
    //                     >
    //                         {item.label}
    //                     </Text>
    //                 </Flex>
    //             ))
    //         }
    //     </Flex>
    // )
}