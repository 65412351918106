import { apiWithoutInterceptor, apiWithInterceptor } from './';

export const login = async (data: {
    email: string;
    password: string;
}) : Promise<any> => {
    return apiWithoutInterceptor.post("/auth/classic", data);
}

export const getToken = async ({
    tokenId,
} : {
    tokenId: string;
}) : Promise<any> => {
    return apiWithoutInterceptor.post("/auth/token", {
        token_id: tokenId,
    });
}

export const refreshToken = async (data: {
    refresh_token: string;
}) => {
    return apiWithInterceptor.post("/login/refresh", data);
}