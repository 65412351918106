import { Box, Button, Card, Flex, NumberInput, Select, Text } from "@mantine/core"
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react"

export default function TablePagination({
    table,
}: {
    table: any
}) {

    return (
        <Card
            withBorder
            w={'100%'}
            h={'fit-content'}
            // mx={'auto'}
            // my={'lg'}
            sx={{ 
                overflow: 'visible',
                borderLeft: 'none!important',
                borderRight: 'none!important',
                borderTop: 'none!important',
                position: 'sticky',
                top: 0,
                zIndex: 50,
            }}
            radius={0}
            p={0}
        >
            <Flex
                justify={'center'}
                align={'center'}
                // gap={10}
                sx={ theme => ({
                    '> *:not(:last-child)': {
                        borderRight: `1px solid ${theme.colors.gray[8]}`,
                    },
                    '> *': {
                        height: '40px!important',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '0.75rem!important',
                    },
                })}
            >
                <Button
                    variant={table.getCanPreviousPage() ? 'light' : 'gray'}
                    size="xs"
                    radius={0}
                    onClick={() => table.getCanPreviousPage() && table.previousPage()}
                    sx={{
                        cursor: table.getCanPreviousPage() ? 'pointer' : 'default',
                    }}
                >
                    <IconChevronLeft size={20} />
                </Button>
                <Box px={'xs'}>
                    <Flex>
                        {`PAGE ${table.getState().pagination.pageIndex + 1} OF ${table.getPageCount()}`}
                    </Flex>
                </Box>
                <Box px={'xs'}>
                    <Flex gap={5} align={'center'}>
                        <Text>
                            GOTO
                        </Text>
                        <NumberInput
                            size="xs"
                            min={1}
                            max={table.getPageCount()}
                            onChange={(value: number) => {
                                table.setPageIndex(value - 1)
                            }}
                            variant="unstyled"
                            defaultValue={table.getState().pagination.pageIndex + 1}
                            w={50}
                        />
                    </Flex>
                </Box>
                <Box px={'xs'}>
                    <Select
                        data={[10, 20, 30, 40, 50].map(pageSize => ({ value: String(pageSize), label: `SHOW ${pageSize}` }))}
                        value={String(table.getState().pagination.pageSize)}
                        onChange={(value: string) => {
                            table.setPageSize(Number(value))
                        }}
                        defaultValue={table.getState().pagination.pageSize}
                        size="xs"
                        variant="unstyled"
                        w={100}
                    />
                </Box>
                <Button
                    variant={table.getCanNextPage() ? 'light' : 'gray'}
                    size="xs"
                    radius={0}
                    onClick={() => table.getCanNextPage() && table.nextPage()}
                    sx={{
                        cursor: table.getCanNextPage() ? 'pointer' : 'default',
                    }}
                >
                    <IconChevronRight size={20} />
                </Button>
            </Flex>
        </Card>
    )

}