import {
    useQuery,
} from '@tanstack/react-query'

import {
    getOpinions,
} from 'api/fetch/opinion';


export const useQueryOpinion = (data: {
    offset?: number,
    limit?: number,
    order?: 'ASC' | 'DESC',
    orderby?: string,
    search?: string,
    post_status?: 'publish' | 'draft' | 'pending',
    cat?: string,
    after?: Date,
    before?: Date,
    voted?: boolean,
    multilang?: boolean,
}, locale = "en") => {
    return useQuery(
        ['opinions', data, locale],
        () => getOpinions(data),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: 0,
        }
    );

};