import {
    useQuery,
} from '@tanstack/react-query'

import {
    RealtimeResponse,
    getChartViews,
    getChartVotes,
    getRealtime,
} from 'api/fetch/analysis';

export const useQueryRealtime = () => {
    const { data, isFetching, refetch } = useQuery(
        ['analysis/realtime'],
        getRealtime,
        {
            retry: true,
            refetchInterval: 5000,
        }
    );

    return {
        data: data as RealtimeResponse | undefined,
        isFetching,
        refetch,
    };
};

export const useQueryViewsChart = (options: {
    before?: string;
    after?: string;
}) => {
    const { data, isFetching, refetch } = useQuery(
        ['analysis/chart/views', options],
        () => getChartViews(options),
        {
            retry: true,
        }
    );

    return {
        data: data,
        isFetching,
        refetch,
    };
};

export const useQueryVotesChart = (options: {
    before?: string;
    after?: string;
}) => {
    const { data, isFetching, refetch } = useQuery(
        ['analysis/chart/votes', options],
        () => getChartVotes(options),
        {
            retry: true,
        }
    );

    return {
        data: data,
        isFetching,
        refetch,
    };
};

