import { Card, Flex, Grid, Title } from "@mantine/core";
import { BrandLogo } from "components";

export default function WarperLoginForm({
    children,
}: {
    children: React.ReactNode;
}) {


    return (
        <Grid style={{ maxWidth: 500 }}>
            <Grid.Col span={12}>
            </Grid.Col>
            <Grid.Col span={12}>
            </Grid.Col>
            <Grid.Col span={12}>
                <Card shadow="sm" padding="xl" radius="md">
                    <Flex direction="column" gap="lg" justify={'center'} align={'center'}>
                        <BrandLogo width={80} />
                        <Title order={1} size={'h3'}>{'Sign In'}</Title>
                        {children}
                    </Flex>
                </Card>
            </Grid.Col>
        </Grid>
    );

}