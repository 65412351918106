import {
    useQuery,
} from '@tanstack/react-query'

import {
    getAccount,
} from 'api/fetch/account';
import { User } from 'api/types';

export const useQueryAccount = () => {
    const { data, isFetching, refetch } = useQuery(
        ['user'], 
        getAccount,
        {
            staleTime: Infinity,
            cacheTime: Infinity,
            retry: false,
        }
    );

    return {
        data: data as User,
        isFetching,
        refetch,
    };
};