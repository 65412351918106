// vendors
import { Box, Container } from '@mantine/core';
import * as React from 'react';

export default function CenterFocus({
    children,
}: {
    children: React.ReactNode;
}) {
    return (
        <>
            <Box className='layout-children' sx={theme => ({
                backgroundImage: 'url(https://source.unsplash.com/random/1920x1080/?nature)',
                backgroundBlendMode: 'soft-light',
                // backgroundBlendMode: 'luminosity',
                backgroundColor: theme.colors.brand[6],
            })}>
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#222a',
                    }}
                >
                    <Container className='layout-CenterFocus-children__container'>
                        {children}
                    </Container>
                </Box>
            </Box>
        </>
    );
}