import { useState } from 'react';
import {
    AppShell,
    Navbar,
    Header,
    Text,
    MediaQuery,
    Burger,
    useMantineTheme,
    Box,
    em,
} from '@mantine/core';
import {
    Header as HeaderUi,
    Navbar as NavbarUi,
} from 'ui';
import { Outlet } from 'react-router-dom';
import { useMediaQuery, useViewportSize } from '@mantine/hooks';

export default function App() {
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);
    const { width } = useViewportSize();

    const isMobile = useMediaQuery(`(max-width: ${em(990)})`);

    return (
        <AppShell
            styles={{
                main: {
                    background: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                    padding: 0,
                    paddingLeft: 300,
                },
            }}
            padding={0}
            navbarOffsetBreakpoint="md"
            navbar={
                <Navbar p="md" hiddenBreakpoint="md" hidden={!opened} width={{ xs: 300 }}>
                    <NavbarUi />
                </Navbar>
            }
            header={
                <>
                    {
                        (width <= 990) && (
                            <Header height={{ base: 60 }} p="md">
                                <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                    <Burger
                                        opened={opened}
                                        onClick={() => setOpened((o) => !o)}
                                        size="sm"
                                        color={theme.colors.gray[6]}
                                        mr="xl"
                                    />
                                    <HeaderUi />
                                </div>
                            </Header>
                        )
                    }
                </>
            }
        >
            <Box
                sx={{
                    position: 'relative',
                    top: isMobile ? 60 : 0,
                }}
            >
                <Outlet />
            </Box>
        </AppShell>
    );
}