import { Box, Button, Card, Flex, LoadingOverlay, SegmentedControl, Select, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useLocalStorage } from "@mantine/hooks";
import { sendNotification } from "api/fetch/notifications";
import { useQueryCategory } from "api/hook/category";
import { useQueryOpinion } from "api/hook/opinions";
import { Category, Opinion } from "api/types";
import { PageHead } from "components";
import { useEffect, useState } from "react";
import { parseErrorMsg } from "utils";

export default function Notifications() {

    const [loading, setLoading] = useState(false);
    const form = useForm({
        initialValues: {
            title: '',
            body: '',
            payload_key: 'single opinion',
            payload_value: '__empty__',
            language: 'en',
            login_status: '',
        },
        validate: {
            title: (value) => value.trim().length > 0 ? null : 'Title is required',
            body: (value) => value.trim().length > 0 ? null : 'Body is required',
            payload_key: (value) => value.trim().length > 0 ? null : 'Payload key is required',
        }
    });
    const [locale, setLocale] = useLocalStorage({ key: 'locale', defaultValue: 'en' });
    useEffect(() => {
        form.setFieldValue('language', locale);
    }, [locale]);

    const [keyComponent, setKeyComponent] = useState(<></>);
    useEffect(() => {
        switch (form.values.payload_key) {
            case 'single opinion':
                form.setFieldValue('payload_value', '');
                setKeyComponent(
                    <OpinionSelect
                        onChangeValue={(opinion) => {
                            if (!opinion) {
                                form.setFieldValue('payload_value', '__empty__');
                                return;
                            }
                            form.setFieldValue('payload_value', String(opinion.id));
                            form.setFieldValue('title', opinion.title as string);
                            if (!opinion.title) {
                                alert('Opinion title is empty in the selected language');
                            }
                        }}
                        value={Number(form.values.payload_value)}
                    />
                );
                break;
            case 'single hashtag':
                form.setFieldValue('payload_value', '');
                setKeyComponent(
                    <CategorySelect
                        onChangeValue={(category) => {
                            if (!category) {
                                form.setFieldValue('payload_value', '__empty__');
                                return;
                            }
                            form.setFieldValue('payload_value', String(category.id));
                            form.setFieldValue('title', category.name as string);
                        }}
                        value={Number(form.values.payload_value)}
                    />
                );
                break;
            case 'home':
            case 'my profile':
            case 'hashtag list':
            case 'trending opinions':
            default:
                setKeyComponent(<></>);
                form.setFieldValue('payload_value', '__empty__');
                break;
        }
    }, [form.values.payload_key]);

    return (
        <Box>
            <PageHead
                title="Notifications"
            />
            <form
                onSubmit={form.onSubmit((values) => {
                    if (loading) return;
                    setLoading(true);
                    sendNotification(values).then(() => {
                        form.reset();
                        form.setFieldValue('language', locale);
                    }).catch((err) => {
                        alert(parseErrorMsg(err));
                    }).finally(() => {
                        setLoading(false);
                    });
                })}
            >
                <Flex direction="column" gap="lg" p="md">
                    <LoadingOverlay visible={loading} />
                    <Box>
                        <Title order={5} style={{ marginBottom: 5 }}>
                            User's language
                        </Title>
                        <SegmentedControl
                            sx={theme => ({
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                            })}
                            color="indigo"
                            data={[
                                // { value: '', label: 'All' },
                                { value: 'en', label: 'English' },
                                { value: 'ar', label: 'Arabic' },
                                { value: 'krd', label: 'Kurdish' },
                            ]}
                            value={form.values.language}
                            onChange={(value) => {
                                form.setFieldValue('language', value);
                                setLocale(value);
                            }}
                        />
                    </Box>
                    <Box>
                        <Title order={5} style={{ marginBottom: 5 }}>
                            User's login status
                        </Title>
                        <SegmentedControl
                            sx={theme => ({
                                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                            })}
                            color="indigo"
                            data={[
                                { value: '', label: 'All' },
                                { value: 'logged_in', label: 'Logged in' },
                                { value: 'not_logged_in', label: 'Not logged in' },
                            ]}
                            value={form.values.login_status}
                            onChange={(value) => form.setFieldValue('login_status', value)}
                        />
                    </Box>
                    <Box>
                        <Select
                            label="Destination"
                            placeholder="Payload Type"
                            data={[
                                { value: 'single opinion', label: 'Single opinion' },
                                { value: 'home', label: 'Home tab' },
                                { value: 'my profile', label: 'Profile tab' },
                                { value: 'single hashtag', label: 'Category page' },
                                { value: 'hashtag list', label: 'All categories page' },
                                { value: 'trending opinions', label: 'Trending opinions tab' },
                                { value: 'voted opinions', label: 'Voted opinions tab' },
                            ]}
                            description="Where the user will be redirected when clicking on the notification"
                            {...form.getInputProps('payload_key')}
                        />
                    </Box>
                    <Box>
                        {keyComponent}
                    </Box>
                    <Box>
                        <TextInput
                            label="Title"
                            placeholder="Title"
                            {...form.getInputProps('title')}
                        />
                    </Box>
                    <Box>
                        <TextInput
                            label="Body"
                            placeholder="Body"
                            {...form.getInputProps('body')}
                        />
                    </Box>
                    <Button type="submit" w={'fit-content'} loading={loading}>
                        Send Notification
                    </Button>
                </Flex>
            </form>
        </Box>
    );

}

const OpinionSelect = ({
    onChangeValue,
    ...props
}: {
    onChangeValue?: (opinion: Opinion) => void;
    [key: string]: any;
}) => {

    const [locale, setLocale] = useLocalStorage({ key: 'locale', defaultValue: 'en' });
    const [search, setSearch] = useState('');
    const { data, isFetching } = useQueryOpinion({
        limit: 10,
        search,
        multilang: false,
    }, locale);


    return (
        <Select
            clearable
            label="Opinion"
            placeholder="Select opinion"
            data={isFetching ? [] : data?.opinions?.map((opinion: Opinion) => {

                // let title = opinion.title_en;
                // if (search) {
                //     if (search.match(/[\u0600-\u06FF]/)) {
                //         title = opinion.title_ar || opinion.title_en || opinion.title_krd;
                //     } else if (search.match(/[a-zA-Z]/)) {
                //         title = opinion.title_en || opinion.title_ar || opinion.title_krd;
                //     } else {
                //         title = opinion.title_krd || opinion.title_ar || opinion.title_en;
                //     }
                // }

                return {
                    value: opinion.id,
                    label: opinion.title,
                };
            })}
            searchable
            onChange={(value) => onChangeValue && data && onChangeValue(data?.opinions?.find((opinion: Opinion) => opinion?.id == Number(value)))}
            onSearchChange={(value) => setSearch(value)}
            {...props}
        />
    );

};

const CategorySelect = ({
    onChangeValue,
    ...props
}: {
    onChangeValue?: (category: Category) => void;
    [key: string]: any;
}) => {

    const [search, setSearch] = useState('');
    const [locale, setLocale] = useLocalStorage({ key: 'locale', defaultValue: 'en' });
    const { data, isFetching } = useQueryCategory({
        offset: 0,
        limit: 10,
        search,
        multilang: false,
    }, locale);

    return (
        <Select
            clearable
            label="Category"
            placeholder="Select category"
            data={isFetching ? [] : data?.categories?.map((category: Category) => {
                return {
                    value: category.id,
                    label: category.name,
                };
            })}
            searchable
            onChange={(value) => onChangeValue && onChangeValue(data?.categories?.find((category: Category) => category?.id == Number(value)))}
            onSearchChange={(value) => setSearch(value)}
            {...props}
        />
    );

}