
import { Avatar, Badge, Box, Drawer, Flex, Text, ThemeIcon } from '@mantine/core'
import { useDisclosure, useToggle } from '@mantine/hooks'
import { IconCircleCheckFilled, IconPlus, IconStarFilled } from '@tabler/icons-react'
import {
    createColumnHelper,
} from '@tanstack/react-table'
import { getUsers } from 'api/fetch/user'
import { User } from 'api/types'
import { PageHead, Table } from 'components'
import RoleBadge from 'components/RoleBadge'
import dayjs from 'dayjs'
import { UserEdit, UserNew } from 'pages'
import { useState } from 'react'


const columnHelper = createColumnHelper<User>()

const columns = [
    columnHelper.accessor(row => row.name, {
        id: 'name',
        enableSorting: false,
        cell: info => (
            <Flex align="center">
                <Avatar size={40} radius="xl" src={
                    info.row.original.profile_picture ? info.row.original.profile_picture.thumbnail.url : undefined
                } sx={{ marginRight: '10px' }}>
                    {String(info.getValue()).split(' ').map((name) => name[0]).join('')}
                </Avatar>
                <Text>{info.getValue()} <RoleBadge role={info.row.original.role}/></Text>
            </Flex>
        ),
        header: () => <span>Full Name</span>,
        footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.stars, {
        id: 'stars',
        cell: info => <Badge variant="light" color="cyan" radius="xl" sx={{ marginLeft: '5px' }}><IconStarFilled size={14} style={{verticalAlign: 'sub'}} /> {info.getValue()}</Badge>,
        header: () => <span>Stars</span>,
        footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.email, {
        id: 'email',
        enableSorting: false,
        cell: info => <Text>{info.getValue()} {info.row.original.email_validated && <ThemeIcon size="md" color='cyan' variant="light" radius="xl"><IconCircleCheckFilled size={16} /></ThemeIcon>}</Text>,
        header: () => <span>Email</span>,
        footer: info => info.column.id,
    }),
    // columnHelper.accessor(row => row.phone_number, {
    //     id: 'phone_number',
    //     enableSorting: false,
    //     cell: info => <Text>{info.getValue()}</Text>,
    //     header: () => <span>Phone Number</span>,
    //     footer: info => info.column.id,
    // }),
    columnHelper.accessor(row => row.education, {
        id: 'education',
        enableSorting: false,
        cell: info => <Text>{info.getValue()}</Text>,
        header: () => <span>Education</span>,
        footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.gender, {
        id: 'gender',
        enableSorting: false,
        cell: info => <Text>{info.getValue()}</Text>,
        header: () => <span>Gender</span>,
        footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.birth_date, {
        id: 'birth_date',
        enableSorting: false,
        cell: info => <Text>{info.getValue() && dayjs(info.getValue()).format('YYYY-MM-DD')}</Text>,
        header: () => <span>Birth Date</span>,
        footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.work, {
        id: 'work',
        enableSorting: false,
        cell: info => <Text>{info.getValue()}</Text>,
        header: () => <span>Work</span>,
        footer: info => info.column.id,
    }),
    columnHelper.accessor(row => row.registered, {
        id: 'registered',
        cell: info => <Text>{dayjs(info.getValue()).format('YYYY-MM-DD HH:mm A')}</Text>,
        header: () => <span>Created</span>,
        footer: info => info.column.id,
    }),
]


export default function Opinions() {

    const [openedNew, { open: openNew, close: closeNew }] = useDisclosure(false);
    const [openedEdit, { open: openEdit, close: closeEdit }] = useDisclosure(false);
    const [toggleKey, toggleData] = useToggle([true, false]);

    const [selectedUser, setSelectedUser] = useState<User | null>(null);

    return (
        <Box>
            <PageHead
                title="Users"
                links={[
                    {
                        label: 'Create',
                        onClick: openNew,
                        icon: <IconPlus size={20} />,
                    }
                ]}
            />
            <Table
                endpoint={async (params: {
                    pageIndex: number,
                    pageSize: number,
                    sorting: any,
                    search: string,
                }) => {
                    const data = await getUsers({
                        offset: params.pageIndex * params.pageSize,
                        limit: params.pageSize,
                        order: params.sorting[0]?.desc === true ? 'DESC' : params.sorting[0]?.desc === false ? 'ASC' : undefined,
                        orderby: params.sorting[0]?.id || 'registered',
                        search: params.search,
                    });
                    return {
                        data: data.users,
                        total: data.total,
                    };
                }}
                cacheKey="opinions"
                columns={columns}
                defaultPageSize={20}
                toggleData={toggleKey}
                onRowClick={(row) => {
                    setSelectedUser(row.original);
                    openEdit();
                }}
            />
            <Drawer opened={openedNew} onClose={closeNew} overlayProps={{ opacity: 0.5 }} title="New User">
                <UserNew
                    onUpdate={() => {
                        toggleData();
                        closeNew();
                    }}
                />
            </Drawer>

            <Drawer opened={openedEdit} onClose={closeEdit} overlayProps={{ opacity: 0.5, blur: 4 }} title="Edit User">
                {
                    selectedUser && (
                        <UserEdit
                            user={selectedUser}
                            onUpdate={() => {
                                toggleData();
                                closeEdit();
                            }}
                        />
                    )
                }
            </Drawer>

        </Box>
    );

}