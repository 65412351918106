import { Box, Button, Grid, PasswordInput, Select, TextInput } from '@mantine/core'
import { useForm } from '@mantine/form';
import { newUser } from 'api/fetch/user';
import { useState } from 'react';
import { parseErrorMsg } from 'utils';

export default function OpinionNew({
    onUpdate,
}: {
    onUpdate?: () => void
}) {

    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            password: '',
            role: 'subscriber',
        },
    });

    const [loading, setLoading] = useState(false);

    return (
        <Box>
            <Box
                p={'md'}
                sx={theme => ({
                    maxWidth: 600,
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                })}
            >
                <form onSubmit={form.onSubmit((values) => {
                    setLoading(true);
                    newUser(values).then(() => {
                        onUpdate && onUpdate();
                        form.reset();
                    }).catch((error) => {
                        alert(parseErrorMsg(error));
                    }).finally(() => {
                        setLoading(false);
                    });
                })}>
                    <Grid>
                        <Grid.Col span={12}>
                            <TextInput
                                label="Full Name"
                                {...form.getInputProps('name')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                label="Email"
                                {...form.getInputProps('email')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <PasswordInput
                                label="Password"
                                {...form.getInputProps('password')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Select
                                label="Role"
                                data={[
                                    { value: 'subscriber', label: 'Subscriber' },
                                    { value: 'editor', label: 'Editor' },
                                ]}
                                {...form.getInputProps('role')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                type="submit"
                                fullWidth
                                loading={loading}
                            >
                                Create
                            </Button>
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Box>
    );

}