import { Box, Button, Grid, MultiSelect, Text, TextInput, Title } from '@mantine/core'
import { useForm } from '@mantine/form';
import { deleteOpinion, updateOpinion } from 'api/fetch/opinion';
import { useQueryCategory } from 'api/hook/category';
import { Category, MediaFile, Opinion } from 'api/types';
import { useEffect, useState } from 'react';
import { parseErrorMsg } from 'utils';
import { modals } from '@mantine/modals';
import MediaLibraryButton from 'components/MediaLibraryButton';


export default function OpinionEdit({
    opinion,
    onUpdate,
}: {
    opinion: Opinion
    onUpdate?: () => void
}) {    

    const form = useForm({
        initialValues: {
            title_krd: opinion?.title_krd || '',
            title_en: opinion?.title_en || '',
            title_ar: opinion?.title_ar || '',
            categories: opinion?.categories ? opinion.categories.map((category: Category) => String(category.id)) : [],
            featured_image: opinion?.featured_image || -1,
            featured_video: opinion?.featured_video || -1,
        } as {
            title_krd: string,
            title_en: string,
            title_ar: string,
            categories: string[],
            featured_image: MediaFile | number,
            featured_video: MediaFile | number,
        },
    });

    const [loading, setLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const [search, setSearch] = useState('');

    const categoryQuery = useQueryCategory({
        offset: 0,
        limit: 100,
        search,
        // include: opinion?.categories ? opinion.categories.map((category: Category) => category.id) : [],
    });


    return (
        <Box>
            <Box
                p={'md'}
                sx={theme => ({
                    maxWidth: 600,
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
                })}
            >
                <form onSubmit={form.onSubmit((values) => {
                    setLoading(true);

                    if ( values.featured_image && typeof values.featured_image === 'object' ) {
                        values.featured_image = (values.featured_image as MediaFile).id;
                    }                    

                    if ( values.featured_video && typeof values.featured_video === 'object' ) {
                        values.featured_video = (values.featured_video as MediaFile).id;
                    }

                    updateOpinion({
                        id: opinion.id,
                        ...values,
                        ...(values.categories ? {
                            categories: values.categories.map((category: string) => Number(category)),
                        } : {
                            categories: [],
                        }),
                        featured_image: values.featured_image as number,
                        featured_video: values.featured_video as number,
                    }).then(() => {
                        onUpdate && onUpdate();
                    }).catch((error) => {
                        alert(parseErrorMsg(error));
                    }).finally(() => {
                        setLoading(false);
                    });
                })}>
                    <Grid>
                        <Grid.Col span={12}>
                            <Title>
                                Title
                            </Title>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                label="Title in Arabic"
                                dir='rtl'
                                sx={{
                                    '& input': {
                                        textAlign: 'right',
                                    }
                                }}
                                {...form.getInputProps('title_ar')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                dir='rtl'
                                sx={{
                                    '& input': {
                                        textAlign: 'right',
                                    }
                                }}
                                label="Title in Kurdish"
                                {...form.getInputProps('title_krd')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <TextInput
                                label="Title in English"
                                {...form.getInputProps('title_en')}
                            />
                        </Grid.Col>

                        <Grid.Col span={12}>
                            <Title>
                                Categories
                            </Title>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <MultiSelect
                                data={[
                                    ...(categoryQuery.data?.categories || []),
                                    ...(opinion?.categories || []),
                                ].map((category: Category) => {
                                    return {
                                        value: String(category.id),
                                        label: `${category.name_ar} - ${category.name_krd} - ${category.name_en} (${category.count})`,
                                    };
                                })}
                                placeholder="Select category"
                                searchable
                                nothingFound="No category found"
                                onSearchChange={setSearch}
                                {...form.getInputProps('categories')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <MediaLibraryButton
                                {...form.getInputProps('featured_image')}
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <MediaLibraryButton
                                {...form.getInputProps('featured_video')}
                                type="video"
                            />
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                type="submit"
                                fullWidth
                                loading={loading}
                                disabled={deleteLoading}
                            >
                                Save
                            </Button>
                        </Grid.Col>
                        <Grid.Col span={12}>
                            <Button
                                fullWidth
                                loading={deleteLoading}
                                disabled={loading}
                                color="red"
                                variant="outline"
                                onClick={() => {
                                    // show confirmation modal
                                    setDeleteLoading(true);
                                    modals.openConfirmModal({
                                        title: 'Please confirm deletion',
                                        children: (
                                            <Text size="sm">
                                                You will not be able to recover this opinion! please confirm deletion or cancel action.
                                            </Text>
                                        ),
                                        labels: { confirm: 'Delete', cancel: 'Cancel' },
                                        onCancel: () => {
                                            setDeleteLoading(false);
                                        },
                                        onConfirm: () => {
                                            deleteOpinion({
                                                id: opinion.id,
                                            }).then(() => {
                                                onUpdate && onUpdate();
                                            }).catch((error) => {
                                                alert(parseErrorMsg(error));
                                            }).finally(() => {
                                                setDeleteLoading(false);
                                            });
                                        },
                                    });

                                }}
                            >
                                Delete
                            </Button>
                        </Grid.Col>
                    </Grid>
                </form>
            </Box>
        </Box>
    );

}