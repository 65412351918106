import { useRoutes } from 'react-router-dom';
import {
    App,
    LoginByEmail,
    LoginForgotPassword,
    Dashboard,
    Opinions,
    Categories,
    User,
    Profile,
    Notifications,
} from "pages";


export default function Router() {

    return useRoutes([
        {
            path: '/App',
            element: <App />,
            children: [
                {
                    path: '',
                    element: <Dashboard />
                },
                {
                    path: 'o',
                    children: [
                        {
                            path: '',
                            element: <Opinions />
                        },
                    ]
                },
                {
                    path: 'cat',
                    children: [
                        {
                            path: '',
                            element: <Categories />
                        },
                    ]
                },
                {
                    path: 'user',
                    children: [
                        {
                            path: '',
                            element: <User />
                        },
                    ]
                },
                {
                    path: 'notifications',
                    children: [
                        {
                            path: '',
                            element: <Notifications />
                        },
                    ]
                },
                {
                    path: 'profile',
                    children: [
                        {
                            path: '',
                            element: <Profile />
                        },
                    ]
                },
            ]
        },
        {
            path: '',
            children: [
                {
                    path: '',
                    element: <LoginByEmail />
                },
                {
                    path: 'forgot',
                    element: <LoginForgotPassword />
                },
            ]
        },
        {
            path: '*',
            element: "404"
        },
    ]);

}


