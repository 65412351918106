import { apiWithInterceptor } from './';
import axios, { CancelTokenSource } from 'axios';

let cancelTokens: { [key: string]: CancelTokenSource } = {};

export const getCategories = async ({
    offset = 0,
    limit = 10,
    include = [],
    exclude = [],
    orderby = "count",
    order = "DESC",
    search = "",
    multilang = true,
}: {
    offset?: number,
    limit?: number,
    include?: number[],
    exclude?: number[],
    orderby?: string,
    order?: string,
    search?: string,
    multilang?: boolean,
}) : Promise<any> => {
    cancelTokens.getCategories && cancelTokens.getCategories.cancel();
    cancelTokens.getCategories = axios.CancelToken.source();
    return apiWithInterceptor.get("/categories", {
        params: { 
            offset, limit, orderby, order, search, multilang,
            ...(include.length > 0 && { include: include.join(',') }),
            ...(exclude.length > 0 && { exclude: exclude.join(',') }),
        },
    });
};

export const newCategory = async ({
    name_ar,
    name_en,
    name_krd,
}: {
    name_ar: string,
    name_en: string,
    name_krd: string,
}) => apiWithInterceptor.post("/categories", { name_ar, name_en, name_krd });

export const updateCategory = async ({
    id,
    name_ar,
    name_en,
    name_krd,
}: {
    id: number,
    name_ar: string,
    name_en: string,
    name_krd: string,
}) => apiWithInterceptor.patch(`/categories/${id}`, { name_ar, name_en, name_krd });


export const deleteCategory = async ({
    id,
}: {
    id: number,
}) => apiWithInterceptor.delete(`/categories/${id}`);
