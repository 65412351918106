import { Box, Button, Card, Flex, NumberInput, Select, Text, TextInput } from "@mantine/core"
import { IconChevronLeft, IconChevronRight } from "@tabler/icons-react"

export default function TableSearch({
    table,
    onSearch,
}: {
    table: any
    onSearch?: (value: string) => void
}) {

    return (
        <Card
            withBorder
            w={'100%'}
            h={'fit-content'}
            sx={{ 
                overflow: 'visible',
                borderLeft: 'none!important',
                borderRight: 'none!important',
                borderTop: 'none!important',
                position: 'sticky',
                top: 0,
                zIndex: 50,
            }}
            radius={0}
            p={0}
        >
            <Flex
                justify={'center'}
                align={'center'}
                // gap={10}
                sx={ theme => ({
                    '> *:not(:last-child)': {
                        borderRight: `1px solid ${theme.colors.gray[8]}`,
                    },
                    '> *': {
                        height: '40px!important',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '0.75rem!important',
                    },
                })}
            >
                <TextInput
                    placeholder="Search"
                    sx={{
                        width: '100%',
                        '& *': {
                            width: '100%',
                        },
                        '& input': {
                            border: 'none!important',
                            textAlign: 'center',
                        },
                    }}
                    onChange={(e) => {
                        const value = e.currentTarget.value;
                        if ( value.length <= 3 && value.length != 0 ) return;
                        onSearch && onSearch(value);
                    }}
                />
            </Flex>
        </Card>
    )

}