import { Flex, Badge, Tooltip } from "@mantine/core";
import { Category } from "api/types";
import { useState } from "react";

export default function CategoriesPills({
    categories,
}: {
    categories: Category[]
}) {

    return (
        <Flex wrap={'wrap'}>
            {
                categories?.map((category, index) => (
                    <CategoryPill key={index} category={category} />
                ))
            }
        </Flex>
    )
}

const CategoryPill = ({
    category,
}: {
    category: Category
}) => {


    return (
        <Tooltip
            label={`${category.name_en} / ${category.name_ar} / ${category.name_krd}`}
            withArrow
            color={'dark'}
            transitionProps={{ transition: 'skew-down', duration: 300 }}
        >
            <Badge
                variant={'light'}
                color={'blue'}
                sx={{
                    margin: '2px',
                }}
            >
                {category.name_ar}
            </Badge>
        </Tooltip>
    )
}