import * as React from "react";
import * as ReactDOM from "react-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query'

import store from "./redux/store";

const queryClient = new QueryClient()

ReactDOM.render(
    (
        <React.StrictMode>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <StoreProvider store={store}>
                        <App />
                    </StoreProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </React.StrictMode>
    ),
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
