import axios from 'axios'

export const Domain = {
    production: "https://curiosity.qarar.me/wp-json/qarar-app/v1/",
    // development: "https://curiosity.qarar.me/wp-json/qarar-app/v1/",
    development: "http://qarar-api.local/wp-json/qarar-app/v1/",
}

const apiWithoutInterceptor = axios.create({
    baseURL: `${process.env.NODE_ENV === "production"
        ? Domain.production
        : Domain.development
        }`,
    headers: {
        "Content-Type": "multipart/form-data",
    },
});

const apiWithInterceptor = axios.create({
    baseURL: `${process.env.NODE_ENV === "production"
        ? Domain.production
        : Domain.development
        }`,
    headers: {
        "Content-Type": "multipart/form-data",
        // "Accept": "application/json",
    },
});

[apiWithInterceptor, apiWithoutInterceptor].forEach((api) => {
    api.interceptors.request.use(async (config) => {
        let locale = localStorage.getItem("locale") || "en";
        // remove double quotes from locale
        locale = locale.replace(/['"]+/g, "");
        const path = config.url?.replace(Domain.production, "").replace(Domain.development, "");
        config.url = process.env.NODE_ENV === "production" ? `${Domain.production}${locale}${path}` : `${Domain.development}${locale}${path}`;
        return config;
    });
});

apiWithInterceptor.interceptors.request.use(async (config) => {
    let token = localStorage?.getItem("token")?.replace(/['"]+/g, "");

    if (token) {
        if (!config.headers) config.headers = {} as any;
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});

// filter all responses and check if the status is 200 and the key success is true then return the data
[apiWithInterceptor, apiWithoutInterceptor].forEach((api) => {
    api.interceptors.response.use((response) => {
        if (response.status === 200 && response.data.status === "success") {
            return response.data.data;
        }
        return response;
    }, (error) => {
        return Promise.reject(error);
    });
});

// if token is not set redirect to / and remove token from local storage
apiWithInterceptor.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (error.response?.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/";
    }
    if (error.response?.status === 403) {
        localStorage.removeItem("token");
        window.location.href = "/";
    }
    // if error code is 400 and code is rest_user_invalid_login do the same
    if (error.response?.status === 400 && error.response?.data?.code === "rest_user_invalid_login") {
        localStorage.removeItem("token");
        window.location.href = "/";
    }
    return Promise.reject(error);
});

export { apiWithInterceptor, apiWithoutInterceptor };