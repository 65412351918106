export default {
    defaultRadius: 5,
    colors: {
        brand: ['#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7', '#2c7af7'],
        vote: ['#FF2626', '#FF6767', '#FFFFFF', '#67ADFF', '#1A83FF'],
        voteInverse: ['#FFFFFF', '#FFFFFF', '#000000', '#FFFFFF', '#FFFFFF'],
    },
    primaryColor: 'indigo',
    colorScheme: 'light',
    headings: {
        // fontFamily: CSSProperties['fontFamily'];
        // fontWeight: CSSProperties['fontWeight'];
        sizes: {
            h1: {
                fontSize: '1.3rem',
            },
            h2: {
                fontSize: '1.1rem',
            },
            h3: {
                fontSize: '1rem',
            },
            h4: {
                fontSize: '0.9rem',
            },
            h5: {
                fontSize: '0.9rem',
            },
            h6: {
                fontSize: '0.9rem',
            },
        }
    },
    dir: 'rtl',
};